import React, { useEffect, useState } from 'react';
import { Collapse, Tooltip } from 'antd';
import Button from 'components/Button/Button';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import EmbeddedWidget from 'components/CodeSnippet/EmbeddedWidget';
import AccessViaEmail from 'components/CodeSnippet/AccessViaEmail';
import IntegrationViaPrivateKey from 'components/CodeSnippet/IntegrationViaPrivateKey';
import CodeSnippet from 'components/CodeSnippet';
import GenerateDataCollectorScript from 'components/CodeSnippet/GenerateDataCollectorScript';
import EditFormFieldCollectors from 'components/Modal/EditFormFieldCollectors';
import privateKeysService from 'lib/api/privateKeysApi';
import artBoardImage from 'assets/ArtBoard.svg';
import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';
import { Pac } from 'lib/api/pacApi';

import { ApiKeyType } from 'lib/enums/apiKey';
import { Container, Description, Title, TitleWrapper } from './styled';

import SidebarButtons from '../SidebarButtons';

const { Panel } = Collapse;

interface IProps {
  pacDetails?: Pac;
}

const ImplementationTab = ({ pacDetails }: IProps) => {
  const isLite = pacDetails?.type === 'lite';

  const widgetId = pacDetails?.widgetUuids[0] || '[WIDGET_ID]';
  const pacId = pacDetails?.uuid || '[PAC_ID]';
  const optionId = pacDetails?.widgets[0]?.topics[0]?.options[0]?.uuid || '[OPTION_ID]';
  const [apiKey, setApiKey] = useState<string>('[INSERT_PUBLIC-API-KEY_HERE]');
  const [formUserId, setFormUserId] = useState<string>('');
  const [formId, setFormId] = useState<string>('');
  const [formConsentId, setFormConsentId] = useState<string>('');
  const [displayEditModal, setDisplayEditModal] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      const [apiKeysResult, formDataCollectorResult] = await Promise.allSettled([
        privateKeysService.get(),
        UsercentricsCmpIntegrationApiService.getDataCollectors(pacDetails?.uuid as string),
      ]);

      if (apiKeysResult.status === 'fulfilled') {
        const apiKeys = apiKeysResult.value;
        setApiKey(apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '');
      } else {
        console.error('Failed to fetch API keys:', apiKeysResult.reason);
      }

      if (formDataCollectorResult.status === 'fulfilled') {
        const formDataCollector = formDataCollectorResult.value;
        setFormId(formDataCollector.data.formSelector);
        setFormUserId(formDataCollector.data.userSelector);
        setFormConsentId(formDataCollector.data.consentsSelector[0].inputSelector);
      } else {
        console.error('Failed to fetch form data collector:', formDataCollectorResult.reason);
      }
    };
    init();
  }, []);

  return (
    <div>
      {isLite ? (
        <EmptyListPlaceholder
          title="Implementation"
          image={artBoardImage}
          description={
            <span>
              <p>
                The UC Preference Manager’s &quot;Audience Unlocker&quot; package enables easy
                website integration. You can capture data using front-end JavaScript or server-side
                code, requiring a Public API key generated from the{' '}
                <strong>
                  <a
                    href={`${window.location.origin}/account-settings`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Account Settings
                  </a>
                </strong>{' '}
                . Add a static link to the Preference Center for user consent management. <br />
                <br />
                Refer to ou{' '}
                <strong>
                  <a
                    href="https://docs.usercentrics.com/#/preference-manager"
                    target="_blank"
                    rel="noreferrer"
                  >
                    documentation
                  </a>
                </strong>{' '}
                for integration and customization details.
              </p>
            </span>
          }
        />
      ) : (
        <EmptyListPlaceholder
          title="Implementation"
          image={artBoardImage}
          description={
            <span>
              <p>
                The UC Preference Manager offers flexible integration options between your
                application and our platform including using standalone Preference Center page,
                embedded widgets, or using your own custom UI - please refer to our{' '}
                <strong>
                  <a
                    href="https://docs.usercentrics.com/#/preference-manager"
                    target="_blank"
                    rel="noreferrer"
                  >
                    documentation
                  </a>
                </strong>{' '}
                for detailed information. <br />
                <br />
                To utilize our public API, you will require a secret API key that can be generated
                under the{' '}
                <strong>
                  <a
                    href={`${window.location.origin}/account-settings`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Account Settings
                  </a>
                </strong>{' '}
                page.
              </p>
            </span>
          }
        />
      )}

      {!isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Embedded widgets</Title>
          </TitleWrapper>
          <Description>
            Integrate specific widgets directly into your website using the following code snippet:
          </Description>
          <Collapse>
            <Panel header="Expand to view code snippet" key="1">
              <EmbeddedWidget />
            </Panel>
          </Collapse>
        </Container>
      )}

      {!isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Stand-alone Preference Center</Title>
          </TitleWrapper>
          <Description>
            Access the dedicated UC Preference Center using the generated token through the
            following link, which can be placed anywhere on your website or mobile app:
          </Description>
          <Collapse>
            <Panel header="Expand to view code snippet" key="2">
              <CodeSnippet copyContent="https://app-preference.preference-management.usercentrics.eu/?token=[JWT_TOKEN]&lng=[LANG_CODE]">
                https://app-preference.preference-management.usercentrics.eu/?token=
                <Tooltip
                  title="In advance generated access token for the given UserID. To obtain a token, execute a direct call to the dedicated Public API end-point."
                  placement="right"
                >
                  <strong>[JWT_TOKEN]</strong>
                </Tooltip>
                &lng=
                <Tooltip
                  title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
                  placement="right"
                >
                  <strong>[LANG_CODE]</strong>
                </Tooltip>
              </CodeSnippet>
            </Panel>
          </Collapse>

          <Description style={{ marginTop: '20px' }}>
            If you don’t want to generate a token for each user, you can use the following link to
            the Preference Center page. In this scenario, users will request an access token via
            email in a self-service manner. Please don&apos;t forget to activate{' '}
            <strong>Authentication via Email</strong> feature in configuration{' '}
            <a
              href={`${window.location.origin}/configurations/${pacId}/settings`}
              target="_blank"
              rel="noreferrer"
            >
              Settings
            </a>
            .
          </Description>
          <Collapse>
            <Panel header="Expand to view code snippet" key="3">
              <CodeSnippet copyContent="https://app-preference.preference-management.usercentrics.eu/login/2340e662-480b-4219-8b01-6fe79927d34a?lng=[LANG_CODE]">
                https://app-preference.preference-management.usercentrics.eu/login/
                <strong>2340e662-480b-4219-8b01-6fe79927d34a</strong>
                ?lng=
                <Tooltip
                  title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
                  placement="right"
                >
                  <strong>[LANG_CODE]</strong>
                </Tooltip>
              </CodeSnippet>
            </Panel>
          </Collapse>
        </Container>
      )}

      {isLite && (
        <>
          <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>Capture preferences from existing HTML form with Javascript code</Title>
              <Button type="border" onClick={() => setDisplayEditModal(true)}>
                Edit
              </Button>
            </TitleWrapper>
            <Description>
              Integrate data capture directly into your website form using the JS code below. Please
              refer to our{' '}
              <a
                href="https://support.usercentrics.com/hc/en-us/articles/16742483326620-Setting-up-your-Audience-Unlocker"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{' '}
              for more details.
            </Description>
            <Description style={{ marginTop: '20px', marginBottom: '20px' }}>
              <strong>Step 1.</strong> Copy the script tag below and paste it into the &lt;head&gt;
              section of your website. Make sure it is placed on all pages, that either contain the
              data submission forms, or the pages where user is landed after submission.
            </Description>
            <Collapse defaultActiveKey={['4']}>
              <Panel header="Expand to view code snippet" key="4">
                <CodeSnippet
                  copyContent={
                    '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>'
                  }
                >
                  {
                    '<script src="https://dc.preference-management.usercentrics.eu/latest/data-collector.js"></script>'
                  }
                </CodeSnippet>
              </Panel>
            </Collapse>
            <Description style={{ marginTop: '20px', marginBottom: '20px' }}>
              <strong>Step 2.</strong> Add the following code right after the data capture form.
            </Description>
            <Collapse defaultActiveKey={['4']}>
              <Panel header="Expand to view code snippet" key="4">
                <GenerateDataCollectorScript
                  widgetUuid={widgetId}
                  pacUuid={pacId}
                  publicApiKey={apiKey}
                  optionUuid={optionId}
                  consentSelector={formConsentId}
                  userIdSelector={formUserId}
                  formSelector={formId}
                />
              </Panel>
            </Collapse>
          </Container>

          <Container style={{ marginTop: '20px' }}>
            <TitleWrapper>
              <Title>
                Capture preferences from existing HTML form with server-side code (recommended)
              </Title>
            </TitleWrapper>
            <Description>
              The more advanced, but more robust option to capture data server-side. Here is an
              example of Python code you can use to process the form submission and capture
              preferences:
            </Description>
            <Collapse>
              <Panel header="Expand to view code snippet" key="4">
                <CodeSnippet
                  copyContent={`url = 'https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences'
headers = {
  'accept': 'application/json',
  'X-API-KEY': '${apiKey}',
  'Content-Type': 'application/json'
}
data = {
  "pacId": "${pacId}",
  "widgetId": "${widgetId}",
  "userId": "[USER_EMAIL]",  # Replace with actual user email
  "answers": [
    "${optionId}"  # Replace with actual option UUID
  ]
}
response = requests.post(url, headers=headers, json=data)`}
                >
                  {' '}
                  url =
                  &apos;https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences&apos;
                  <br />
                  {'headers = {'}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&apos;accept&apos;: &apos;application/json&apos;,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&apos;X-API-KEY&apos;:{' '}
                  <strong>&apos;{apiKey}&apos;</strong>,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&apos;Content-Type&apos;: &apos;application/json&apos;,
                  <br />
                  {'}'}
                  <br />
                  {'data = {'}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&apos;pacId&apos;: <strong>&quot;{pacId}&quot;</strong>,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&apos;widgetId&apos;:{' '}
                  <strong>&quot;{widgetId}&quot;</strong>,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&quot;userId&quot;: &quot;<strong>[USER_EMAIL]</strong>
                  &quot;, # Replace with actual user email
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&quot;answers&quot;: [
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &quot;<strong>{optionId}</strong>
                  &quot; # Replace with actual option UUID
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;]
                  <br />
                  {'}'}
                  <br />
                  response = requests.post(url, headers=headers, json=data)
                </CodeSnippet>
              </Panel>
            </Collapse>
          </Container>
        </>
      )}

      {isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Dedicated Preference Center with access via Email</Title>
          </TitleWrapper>
          <Description>
            Put this link onto your website, so your users can request an access link to their email
            and open the Preference Center to adjust their consents in a self-service manner.
          </Description>
          <Collapse>
            <Panel header="Expand to view code snippet" key="6">
              <AccessViaEmail pacId={pacId} />
            </Panel>
          </Collapse>
        </Container>
      )}

      {!isLite && (
        <Container style={{ marginTop: '20px' }}>
          <TitleWrapper>
            <Title>Integration using Private API key</Title>
          </TitleWrapper>
          <Description>
            You can integrate UC Preference Manager using our public API with a dedicated private
            API key available in the{' '}
            <strong>
              <a
                href={`${window.location.origin}/account-settings`}
                target="_blank"
                rel="noreferrer"
              >
                Account Settings
              </a>
            </strong>{' '}
            page. Using this method, you can build your own custom interfaces or implement custom
            integrations with other systems - please refer to our{' '}
            <a
              href="https://docs.usercentrics.com/#/preference-manager-api"
              target="_blank"
              rel="noreferrer"
            >
              Public API documentation
            </a>{' '}
            for detailed information.
          </Description>
          <Collapse>
            <Panel header="Expand to view code snippet" key="2">
              <IntegrationViaPrivateKey
                apiKey={apiKey}
                pacId={pacId}
                widgetId={widgetId}
                optionId={optionId}
              />
            </Panel>
          </Collapse>
        </Container>
      )}

      {displayEditModal && (
        <EditFormFieldCollectors
          pacUuid={pacId}
          optionUuid={optionId}
          onClose={() => setDisplayEditModal(false)}
          formUserId={formUserId}
          setFormUserId={setFormUserId}
          formId={formId}
          setFormId={setFormId}
          formConsentId={formConsentId}
          setFormConsentId={setFormConsentId}
        />
      )}

      <SidebarButtons
        setHasUnsavedChanges={() => false}
        onSave={() => null}
        isSaveDisabled
        isPacDeactivated={!!pacDetails?.deactivated}
      />
    </div>
  );
};

export default ImplementationTab;
