import { AxiosResponse } from 'axios';
import { AbstractApiService } from './abstractApiService';
import { IntegrationApiAxios } from './axiosInstance';
import { ICmpIntegration } from '../interfaces/cmpIntegration';

interface FormDataCollector {
  pacUuid: string;
  formSelector: string;
  userSelector: string;
  consentsSelector: [
    {
      optionUuid: string;
      inputSelector: string;
    },
  ];
}

class UsercentricsCmpIntegrationApiService extends AbstractApiService {
  create(newIntegration: any): Promise<AxiosResponse<ICmpIntegration>> {
    return IntegrationApiAxios.post('cmp/configs', newIntegration, this.getApiHeaders());
  }

  getDataCollectors(pacUuid: string): Promise<AxiosResponse<FormDataCollector>> {
    return IntegrationApiAxios.get('form-data-collectors', {
      params: {
        pacUuid,
      },
      ...this.getApiHeaders(),
    });
  }

  updateDataCollectors(data: FormDataCollector): Promise<AxiosResponse<FormDataCollector>> {
    return IntegrationApiAxios.put('form-data-collectors', data, this.getApiHeaders());
  }

  getById(pacUuid: string): Promise<AxiosResponse<Array<ICmpIntegration>>> {
    return IntegrationApiAxios.get('cmp/configs', {
      params: {
        pacUuid,
      },
      ...this.getApiHeaders(),
    });
  }

  deleteById(integrationUuid: string): Promise<AxiosResponse> {
    return IntegrationApiAxios.delete(`cmp/configs/${integrationUuid}`, {
      ...this.getApiHeaders(),
    });
  }

  edit(
    integrationUuid: string,
    payload: {
      title: string;
      settingsId: string;
      consentRequired: boolean;
    },
  ): Promise<AxiosResponse<ICmpIntegration>> {
    return IntegrationApiAxios.patch(
      `cmp/configs/${integrationUuid}`,
      payload,
      this.getApiHeaders(),
    );
  }

  getSessionByPacUuid(userId: string, pacUuid: string): Promise<AxiosResponse> {
    return IntegrationApiAxios.get('cmp/sessions/', {
      params: {
        pacUuid,
      },
      ...this.getApiHeaders({ 'x-user-id': userId }),
    });
  }

  deleteSessionById(sessionId: string): Promise<AxiosResponse> {
    return IntegrationApiAxios.delete(`cmp/sessions/${sessionId}`, {
      ...this.getApiHeaders(),
    });
  }
}

export default new UsercentricsCmpIntegrationApiService();
