import React, { useState } from 'react';
import { Form, message } from 'antd';
import Modal from 'components/Modal/Modal';
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { IWidget } from 'lib/interfaces/widget';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';
import { showErrorResponseNotification } from 'lib/helpers';
import { numericTenSymbols } from 'lib/regex/validations';
import { GcmStep } from 'lib/enums/steps';

import DetailsStep from './DetailsStep';
import GoogleAuthStep from './GoogleAuthStep';
import GoogleAdsStep from './GoogleAdsStep';

interface IProps {
  onClose: () => void;
  widgetsList: Array<IWidget>;
  pacUuid: string;
}

const GCMIntegrationModal = ({ widgetsList, onClose, pacUuid }: IProps) => {
  const [currentStep, setCurrentStep] = useState(GcmStep.DETAILS);
  const [form] = Form.useForm();
  const [googleAccount, setGoogleAccount] = useState<string | null>(null);
  const [googleToken, setGoogleToken] = useState<string | null>(null);
  const [adsAccount, setAdsAccount] = useState('');
  const [customerListId, setCustomerListId] = useState('');
  const [customerListName, setCustomerListName] = useState('');
  const [selectedWidget, setSelectedWidget] = useState<string>('');
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/adwords',
    onSuccess: async (response) => {
      if (
        response.scope.includes('email') &&
        response.scope.includes('https://www.googleapis.com/auth/adwords')
      ) {
        try {
          const credentialsResponse = await GCMIntegrationApiService.getCredentials(response.code);

          setGoogleAccount(credentialsResponse.data.email);
          setGoogleToken(credentialsResponse.data.refreshToken);
          message.success('Google account connected successfully!');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      } else {
        message.error('Requested scopes are not granted');
      }
    },
    onError: () => {
      message.error('Google login failed. Please try again.');
    },
  });

  const handleNextStep = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1);
      })
      .catch(() => {
        message.error('Validation Failed');
      });
  };

  const handlePreviousStep = () => {
    if (currentStep === GcmStep.DETAILS) {
      onClose();
    }
    setCurrentStep(currentStep - 1);
  };

  const handleSaveConfiguration = async () => {
    form.validateFields().catch((info) => {
      message.error(`Validation Failed: ${info}`);
    });
    if (
      !numericTenSymbols.pattern.test(adsAccount) ||
      !numericTenSymbols.pattern.test(customerListId)
    ) {
      message.error('Customer ID or Customer List ID are empty');
      return;
    }
    try {
      await GCMIntegrationApiService.createConfig({
        pacUuid,
        title: form.getFieldValue('title'),
        consentSignalsSource: {
          widgetUuid: selectedWidget,
          topicUuid: selectedTopic,
          optionUuidsFor: {
            userData: selectedOption,
            personalization: selectedOption,
          },
        },
        refreshToken: googleToken || '',
        email: googleAccount || '',
        customerId: adsAccount,
        customerListId,
        customerListName,
      });
      message.success('GCM Configuration saved successfully!');
      onClose();
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case GcmStep.DETAILS:
        return (
          <DetailsStep
            form={form}
            widgetsList={widgetsList}
            setSelectedWidget={setSelectedWidget}
            setSelectedTopic={setSelectedTopic}
            setSelectedOption={setSelectedOption}
            selectedWidget={selectedWidget}
            selectedTopic={selectedTopic}
          />
        );
      case GcmStep.GOOGLE_AUTH:
        return (
          <GoogleAuthStep
            googleAccount={googleAccount}
            setGoogleAccount={setGoogleAccount}
            login={login}
          />
        );
      case GcmStep.GOOGLE_ADS:
        return (
          <GoogleAdsStep
            setAdsAccount={setAdsAccount}
            setCustomerListId={setCustomerListId}
            setCustomerListName={setCustomerListName}
            googleToken={googleToken}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      visible
      title="Google Customer Match Integration"
      secondaryButton={{
        label: currentStep === GcmStep.DETAILS ? 'Cancel' : 'Back',
        onClick: handlePreviousStep,
      }}
      primaryButton={{
        disabled: currentStep === GcmStep.GOOGLE_AUTH && googleAccount === null,
        label: currentStep < GcmStep.GOOGLE_ADS ? 'Next' : 'Save',
        onClick: currentStep < GcmStep.GOOGLE_ADS ? handleNextStep : handleSaveConfiguration,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
    >
      {renderStepContent()}
    </Modal>
  );
};

const GCMIntegration = ({ widgetsList, onClose, pacUuid }: IProps) => (
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <GCMIntegrationModal widgetsList={widgetsList} onClose={onClose} pacUuid={pacUuid} />
  </GoogleOAuthProvider>
);

export default GCMIntegration;
