import React, { useEffect, useState } from 'react';
import { Description, Title } from 'shared-components/ModalsContent';
import { Form, Select, Spin } from 'antd';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';
import { showErrorResponseNotification } from 'lib/helpers';
import { IGcmDetailsData } from 'lib/interfaces/googleCustomerMatch';
import { Flex } from '../../../shared-components/Flex';

interface IProps {
  googleToken: string | null;
  setAdsAccount: (value: string) => void;
  setCustomerListId: (value: string) => void;
  setCustomerListName: (value: string) => void;
}

export default ({ setAdsAccount, setCustomerListId, googleToken, setCustomerListName }: IProps) => {
  const [form] = Form.useForm();
  const [gcmDetailsData, setGcmDetailsData] = useState<Array<IGcmDetailsData>>();
  const [selectedAdsAccount, setSelectedAdsAccount] = useState<IGcmDetailsData>();
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      if (!googleToken) {
        return;
      }
      setAdsAccount('');
      setCustomerListId('');
      setLoading(true);
      try {
        const { data } = await GCMIntegrationApiService.getCustomerDataByRefreshToken(googleToken);

        setGcmDetailsData(data);
      } catch (error) {
        showErrorResponseNotification(error);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, [googleToken]);

  return (
    <>
      <Title>Select Customer segment list</Title>
      <Description>
        Please choose Google Ads Account and define Customer Segment list the data will be saved to.
      </Description>
      <Form layout="vertical" style={{ padding: '16px' }} form={form}>
        {isLoading && (
          <Flex alignItems="center" justifyContent="center">
            <Spin />
          </Flex>
        )}
        {!isLoading && (
          <>
            <Form.Item
              name="customerId"
              rules={[{ required: true, message: 'Please select your Google Ads Customer ID.' }]}
            >
              <Select
                placeholder="Google Ads Account (CustomerID)"
                onChange={(value) => {
                  setSelectedAdsAccount(gcmDetailsData?.find((d) => d.id === value));
                  setAdsAccount(value.toString());
                  setCustomerListId('');
                  form.setFieldValue('customerListId', '');
                }}
              >
                {gcmDetailsData?.map((details) => (
                  <Select.Option key={details.id} value={details.id}>
                    {details.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="customerListId"
              rules={[{ required: true, message: 'Please select your Customer List ID.' }]}
            >
              <Select
                placeholder="Customer List ID"
                onChange={(value) => {
                  if (!selectedAdsAccount) {
                    return;
                  }

                  const selectedCustomer = selectedAdsAccount.customerList.find((customer) => {
                    return customer.id === value;
                  });
                  setCustomerListName(selectedCustomer?.name || '');
                  setCustomerListId(value.toString());
                }}
              >
                {selectedAdsAccount?.customerList?.map((details) => (
                  <Select.Option key={details.id} value={details.id}>
                    {details.name} ({details.id})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};
